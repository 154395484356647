import "../../styles/section.css";
import "../franquicia/franquicia.scss";
import "../menus/menus.css";
import "./reserva-grupos.scss";

import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { graphql } from "gatsby";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from "gatsby-plugin-react-i18next";
import { Link as AnchorLink } from "react-scroll";

import Layout from "../../components/layout/Layout";
import Seo from "../../components/seo";
import ReservaGruposForm from "../../components/contact/ReservasGrupoForm";
import Menu from "../../components/carta/Menu";

import FlorituraHoja from "../../svg/florituras/florituraHoja.svg";
import Subrayado5 from "../../svg/subrayados/subrayado05.svg";
import Separador from "../../svg/lineaSeparador.svg";
import Mask1 from "../../svg/masks/mask1.svg";
import Mask2 from "../../svg/masks/mask2.svg";
import Mask5 from "../../svg/masks/mask5.svg";
import MaskSq1 from "../../svg/masks/maskSq1.svg";
import MaskSq2 from "../../svg/masks/maskSq2.svg";
import MaskSq3 from "../../svg/masks/maskSq3.svg";
import MaskSq4 from "../../svg/masks/maskSq4.svg";
import IconPlane from "../../svg/icons/reservaGrupos/iconPaperPlane.svg";
import IconHeart from "../../svg/icons/reservaGrupos/iconHeart.svg";
import IconArrows from "../../svg/icons/reservaGrupos/iconArrows.svg";
import Subrayado1 from "../../svg/subrayados/subrayado01.svg";

export default function IndexPage({ data }) {
	// console.log(data);
	// const { language, t } = useTranslation();
	const { t } = useTranslation();

	const [activeMenu, setActiveMenu] = React.useState("menuDegustacion");

	const menus = {
		// menuGrupo: t("Menú de grupos"),
		// menuDegustacion: t("Menú degustación"),
		menuDegustacionNavidad: t("Menú degustación"),
	};

	const anchorLinkProps = {
		smooth: true,
		duration: 750,
		offset: -100,
		delay: 0,
	};

	return (
		<Layout
			footerText={t("Pequeños momentos como este")}
			className="invertColors"
		>
			<Seo title={t("Reserva de grupos")} />

			<div id="reservaGrupos" className="section invertColors">
				<div className="tituloReserva">
					<h1 className="lowercase">
						<Trans i18nKey="ReservasGruposTitle">
							Cuéntanos más de tu <i>reserva de grupo</i>
						</Trans>
					</h1>
					<h2 className="lowercase">
						<Trans>
							Puedes ver más detalles del proceso y los menús disponibles {"  "}
							<AnchorLink to="detalles" {...anchorLinkProps}>
								más abajo
							</AnchorLink>
						</Trans>
					</h2>
				</div>

				<ReservaGruposForm restaurantData={data.restaurants} />

				{/* Importing masks to use them as clipping masks for backgrounds */}
				<Mask1 />
				<Mask2 />
				<Mask5 />
				<MaskSq1 />
				<MaskSq2 />
				<MaskSq3 />
				<MaskSq4 />

				<div id="franquiciasDiferenciadores" className="section invertColors">
					<h1 className="lowercase" id="detalles">
						<Trans i18nKey="ReservasGruposComoTitle">
							¿Como funciona la <i>reserva de grupos</i>?
						</Trans>
					</h1>
					<Swiper
						className="onlyMobile diferenciadoresSlider"
						slidesPerView={"auto"}
						spaceBetween={30}
						centeredSlides={true}
						modules={[Pagination, Navigation]}
						pagination={true}
						navigation={true}
					>
						<SwiperSlide>
							<div className="franquiciaFlex">
								<div>
									<IconPlane className="franquiciaIcon" />
									<h2>
										<Trans>Consulta</Trans>
									</h2>
									<p>
										<Trans>
											Nuestro equipo está trabajando para que tu reserva sea
											efectiva, y contactará contigo una vez envíes
											correctamente el formulario entre 24 y 48 horas de días
											laborables.
										</Trans>
									</p>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="franquiciaFlex">
								<div>
									<IconHeart className="franquiciaIcon" />
									<h2>
										<Trans>Confirmación</Trans>
									</h2>
									<p>
										<Trans>
											Una vez se apruebe la reserva te enviaremos un email donde
											encontrarás un link para hacer un prepago del 20% del
											total del menú a modo de reserva.
										</Trans>
									</p>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="franquiciaFlex">
								<div>
									<IconArrows className="franquiciaIcon" />
									<h2>
										<Trans>Cancelación</Trans>
									</h2>
									<p>
										<Trans>
											Las reservas podrán cancelarse o modificarse hasta 72
											horas antes de su celebración. En caso de cancelarse
											posteriormente, Begin se reserva el derecho a no
											reembolsar el importe abonado.
										</Trans>
									</p>
								</div>
							</div>
						</SwiperSlide>
					</Swiper>
					<div className="franquiciaFlex onlyDesktop">
						<div>
							<IconPlane className="franquiciaIcon" />
							<h2>
								<Trans>Consulta</Trans>
							</h2>
							<p>
								<Trans>
									Nuestro equipo está trabajando para que tu reserva sea
									efectiva, y contactará contigo una vez envíes correctamente el
									formulario entre 24 y 48 horas de días laborables.
								</Trans>
							</p>
						</div>
						<div>
							<IconHeart className="franquiciaIcon" />
							<h2>
								<Trans>Confirmación</Trans>
							</h2>
							<p>
								<Trans>
									Una vez se apruebe la reserva te enviaremos un email donde
									encontrarás un link para hacer un prepago del 20% del total
									del menú a modo de reserva.
								</Trans>
							</p>
						</div>
						<div>
							<IconArrows className="franquiciaIcon" />
							<h2>
								<Trans>Cancelación</Trans>
							</h2>
							<p>
								<Trans>
									Las reservas podrán cancelarse o modificarse hasta 72 horas
									antes de su celebración. En caso de cancelarse posteriormente,
									Begin se reserva el derecho a no reembolsar el importe
									abonado.
								</Trans>
							</p>
						</div>
					</div>
					<h3>
						<AnchorLink to="reservaGruposForm" {...anchorLinkProps}>
							<Trans>Solicitud de reserva</Trans>
							<Subrayado1 />
						</AnchorLink>
					</h3>
				</div>

				<div id="carta" className="invertColors menu">
					<div style={{ display: "none" }}>
						<h1 className="lowercase">
							<Trans i18nKey="ReservasGruposMenusTitle">
								<i>Menús</i> disponibles
							</Trans>
						</h1>
						<div className={`cartaTopLinks menuTopLinks`}>
							{Object.keys(menus).map((menu) => {
								return (
									<AnchorLink
										{...anchorLinkProps}
										to="cartaBeginning"
										onClick={() => setActiveMenu(menu)}
										key={menu}
										className={`${activeMenu === menu ? "active" : ""}`}
									>
										<h2>{menus[menu]}</h2>
									</AnchorLink>
								);
							})}
						</div>
						<Separador className="menuSeparador" />
					</div>

					<Menu
						activeLocal="origen"
						activeMenu={activeMenu}
						menus={menus}
						grupos={true}
					/>
				</div>

				<div className="dataProtection">
					<p>
						<Trans ns="dataProtection">
							Información básica sobre protección de datos
						</Trans>
						:
					</p>
					<table>
						<tbody>
							<tr>
								<td>
									<Trans ns="dataProtection">Responsable</Trans>:
								</td>
								<td>BEGIN RESTAURANTES, S.L.</td>
							</tr>
							<tr>
								<td>
									<Trans ns="dataProtection">Domicilio</Trans>:
								</td>
								<td>
									Av. Cortes Valencianas, 50, CP 46015 Valencia (Valencia)
								</td>
							</tr>
							<tr>
								<td>
									<Trans ns="dataProtection">Finalidad</Trans>:
								</td>
								<td>
									<Trans ns="dataProtection">
										Atender su solicitud de información
									</Trans>
									.
								</td>
							</tr>
							<tr>
								<td>
									<Trans ns="dataProtection">Legitimacion</Trans>
								</td>
								<td>
									<Trans ns="dataProtection">
										Sus datos serán tratados solo con su consentimiento, al
										marcar la casilla mostrada en este formulario
									</Trans>
									.
								</td>
							</tr>
							<tr>
								<td>
									<Trans ns="dataProtection">Destinatarios</Trans>:
								</td>
								<td>
									<Trans ns="dataProtection">
										Sus datos no serán cedidos a terceros
									</Trans>
									.
								</td>
							</tr>
							<tr>
								<td>
									<Trans ns="dataProtection">Derechos</Trans>:
								</td>
								<td>
									<Trans ns="dataProtection">
										Tiene derecho a solicitarnos acceder a sus datos,
										corregirlos o eliminarlos, también puede solicitarnos
										limitar su tratamiento, oponerse a ello y a la portabilidad
										de sus datos, dirigiéndose a nuestra dirección postal o a
									</Trans>{" "}
									<a href="mailto:contacto@beginrestaurante.com">
										contacto@beginrestaurante.com
									</a>
								</td>
							</tr>
							<tr>
								<td>
									<Trans ns="dataProtection">Mas info</Trans>:
								</td>
								<td>
									<Trans ns="dataProtection">
										Dispone de más información en nuestra
									</Trans>
									<Link to="/legal/pollitica-privacidad">
										<Trans ns="dataProtection">Política de Privacidad</Trans>
									</Link>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<FlorituraHoja className="footerFloritura" />
				<div className="prefooterNote">
					<h2>
						<Trans>
							También tenemos la opción de{" "}
							<a href="https://linktr.ee/begintakeaway">
								recoger en local
								<Subrayado5 />
							</a>{" "}
							o de{" "}
							<a href="https://linktr.ee/beginrestaurante">
								envío a domicilio
								<Subrayado5 />
							</a>{" "}
							,
						</Trans>
					</h2>
					<h2>
						<Trans>¡Tú decides cómo vivir la experiencia!</Trans>
					</h2>
				</div>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}

		restaurants: allMarkdownRemark(
			filter: { fields: { language: { eq: $language } } }
			sort: { order: ASC, fields: fileAbsolutePath }
		) {
			nodes {
				fields {
					language
				}
				frontmatter {
					id
					coverManagerId
					simpleName
					address1
					addressCity
					addressLink
					addressPostal
					deliveryLinks {
						name
						url
					}
					hours {
						brunch
						dinner
						lunch
					}
					maxPeople
					menuBrunch
					menuDia
					menuNavidad
					menuDegustacionNavidad
					menuGrupo
					menuNoche
					takeAwayLink
					subtitle
					title
					images {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`;
